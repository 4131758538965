import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'PhoneNumber/user';

export function usePhoneNumberUserQuery({
	cacheTime,
	refetchOnWindowFocus,
	enabled,
}: { cacheTime?: number; refetchOnWindowFocus?: boolean; enabled?: boolean } = {}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		refetchOnWindowFocus,
		cacheTime,
		queryFn: () => userSession.webServiceHelper.callAsync<Api.ITelephonyConfiguration[]>('PhoneNumber/User', 'GET'),
		queryKey: [BASE_QUERY_KEY],
	});
}

export function invalidatePhoneNumberUserQuery() {
	reactQueryClient.invalidateQueries({ queryKey: [BASE_QUERY_KEY] });
}
