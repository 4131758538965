import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

const USER_QUERY_KEY = 'user';

export function useUserQuery({
	impersonationContext,
	id,
	onError,
	onSuccess,
	enabled = true,
}: {
	id: string;
	impersonationContext?: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (user: Api.IUser) => void;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		onSuccess,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IUser>(
				Api.ImpersonationBroker.composeApiUrl({ impersonationContext, urlPath: `user/${id}` }),
				'GET'
			);
		},
		queryKey: [USER_QUERY_KEY, id, impersonationContext],
		retry: 1,
	});
}
