import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = [`telephony/text/settings`];

export function useTelephonyTextSettingsQuery({
	cacheTime,
	refetchOnWindowFocus,
	enabled,
}: { cacheTime?: number; refetchOnWindowFocus?: boolean; enabled?: boolean } = {}) {
	const userSession = useUserSession();
	return useQuery({
		cacheTime,
		enabled,
		refetchOnWindowFocus,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<Api.TextSettings>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `telephony/text/settings`,
				}),
				'GET'
			);
		},
		queryKey: BASE_QUERY_KEY,
	});
}

export function invalidateTelephonyTextSettingsQuery() {
	reactQueryClient.invalidateQueries({ queryKey: BASE_QUERY_KEY });
}
